<template>
    <picture :class="$attrs.class" class="relative">
        <source v-for="item of generateSource()" :media="item?.media" :srcset="item?.srcset">
        <img ref="imgRef" v-bind="inputAttrs" class="absolute inset-0 h-full w-full object-cover object-bottom"></img>
        

    </picture>

</template>

<script lang="ts" setup>

    import { joinURL } from 'ufo'

    defineOptions({
        inheritAttrs: false
    })






    const attrs = useAttrs()





    const inputAttrs = computed(() => {
        let returnObj: { [key: string]: any } = {}
        // use const below
        for (const attr in attrs) {
            if (attr !== 'class') {
                returnObj[attr] = attrs[attr]
            }
        }
        return returnObj;
    })





    const props = withDefaults(defineProps<Props>(), {
        debug: false,
        quality: 30,
        dpr: 1,
        format: 'auto',
        fit: 'crop',
        smBkp: '640px',
        mdBkp: '768px',
        lgBkp: '1024px',
        xlBkp: '1280px',
        xxlBkp: '1536px'
    })










    let getInfo: any;

    if (import.meta.dev && props.debug && import.meta.client) {

        const breakpoints = [
            { bkp: 'xxl', val: '(min-width:' + props.xxlBkp + ')' },
            { bkp: 'xl', val: '(min-width:' + props.xlBkp + ')' },
            { bkp: 'lg', val: '(min-width:' + props.lgBkp + ')' },
            { bkp: 'md', val: '(min-width:' + props.mdBkp + ')' },
            { bkp: 'sm', val: '(min-width:' + props.smBkp + ')' },
            { bkp: 'default', val: 'not all and (min-width:' + props.smBkp + ')' }
        ];
        const imgElem = useTemplateRef('imgRef')
        const imgSize = ref({ W: imgElem.value?.naturalWidth || 0, H: imgElem.value?.naturalHeight || 0 })
        const debugEl = useTemplateRef('debugEl');

        const windowWidth = ref<number>()
        const getWindowSize = () => {
            windowWidth.value = window.innerWidth
        }

        onMounted(() => {
            
            window.addEventListener('resize', getWindowSize)
            if (imgElem.value != null) {
                imgElem.value.onload = (ev => {
                    imgSize.value = { W: imgElem.value?.naturalWidth || 0, H: imgElem.value?.naturalHeight || 0 }
                })
                imgSize.value = { W: imgElem.value?.naturalWidth || 0, H: imgElem.value?.naturalHeight || 0 }
            }
        })

        onUnmounted(() => {
            window.removeEventListener('resize', getWindowSize)
        })


        getInfo = computed(() => {
            if (debugEl.value && import.meta.client) return {
                windowWidth: windowWidth.value || window.innerWidth,
                currentbkp: breakpoints.find(({ bkp, val }) => window.matchMedia(val).matches)?.bkp,
                pixelRatio: window.devicePixelRatio,
                imgElem: {
                    W: debugEl.value.offsetWidth, H: debugEl.value.offsetHeight
                },
                img: imgSize.value

            }
        })
    }


    if (props.preload) {
        useHead({
            link:
                generateSource().map(({ srcset, media }) => ({
                    rel: 'preload',
                    as: 'image',
                    imagesrcset: srcset,
                    media,
                    fetchpriority: 'high'
                }))
        })
    }

    function generateSource() {
        const breakpoints = [
            { variant: props.smVariant, bkp: props.smBkp },
            { variant: props.mdVariant, bkp: props.mdBkp },
            { variant: props.lgVariant, bkp: props.lgBkp },
            { variant: props.xlVariant, bkp: props.xlBkp },
            { variant: props.xxlVariant, bkp: props.xxlBkp }
        ];

        const usedBreakpoints = breakpoints.filter(v => v.variant?.length)
        const firstUsedBreakpoint = usedBreakpoints[0]?.bkp || '';
        const sources = usedBreakpoints.map(({ variant, bkp }, i, ar) => {
            const next = ar[i + 1]

            const mediaCondition = next && next.bkp
                ? `(min-width: ${bkp}) and (max-width: ${Number(next.bkp.split('px')[0]) - 1}px)`  // Si next est défini
                : `(min-width: ${bkp})`;  // Sinon, juste min-width


            return {
                srcset: generateSrcset(variant),
                media: mediaCondition,
            }
        })

        // const sources = breakpoints
        //     .filter(({ variant }) => variant?.length)
        //     .map(({ variant, bkp }) => ({
        //         srcset: generateSrcset(variant),
        //         media: `(min-width: ${bkp})`
        //     }));

        if (props.defaultVariant.length) {
            sources.push({
                srcset: generateSrcset(props.defaultVariant),
                media: firstUsedBreakpoint ? `not all and (min-width: ${firstUsedBreakpoint})` : ''
            });
        }

        return sources;
    }

    function generateSrcset(variants: AirImgVariant[] = []) {
        return variants.map(variant => {
            let gravity = Array.isArray(props.gravity) ? `gravity=${props.gravity?.join('x')}` : props.gravity ? `gravity=${props.gravity}` : '';
            gravity = Array.isArray(variant.gravity) ? `gravity=${variant.gravity?.join('x')}` : variant.gravity ? `gravity=${variant.gravity}` : gravity

            const params = [
                variant.width || props.width ? `w=${variant.width || props.width}` : '',
                variant.height || props.height ? `h=${variant.height || props.height}` : '',
                variant.blur || props.blur ? `blur=${variant.blur || props.blur}` : '',
                variant.brightness || props.brightness ? `brightness=${variant.brightness || props.brightness}` : '',
                variant.compression || props.compression ? `compression=${variant.compression || props.compression}` : '',
                variant.contrast || props.contrast ? `contrast=${variant.contrast || props.contrast}` : '',
                variant.dpr || props.dpr ? `dpr=${variant.dpr || props.dpr}` : '',
                variant.fit || props.fit ? `fit=${variant.fit || props.fit}` : '',
                variant.format || props.format ? `format=${variant.format || props.format}` : '',
                variant.gamma || props.gamma ? `gamma=${variant.gamma || props.gamma}` : '',
                variant.metadata || props.metadata ? `metadata=${variant.metadata || props.metadata}` : '',
                variant.onerror || props.onerror ? `onerror=${variant.onerror || props.onerror}` : '',
                variant.quality || props.quality ? `quality=${variant.quality || props.quality}` : '',
                variant.rotate || props.rotate ? `rotate=${variant.rotate || props.rotate}` : '',
                variant.sharpen || props.sharpen ? `sharpen=${variant.sharpen || props.sharpen}` : '',
                variant.trim || props.trim ? `trim=${variant.trim || props.trim}` : '',
                gravity
            ].filter(Boolean).join(',');

            const url = joinURL('/_icf', params + '__', props.src);
            return (variant.dpr || props.dpr) ? `${url} ${variant.dpr || props.dpr}x` : url;
        }).join(', ') || '';
    }

    export interface AirImgVariant {
        anim?: boolean;
        background?: string;
        /**1 <-> 250 */
        blur?: number;
        /**0 <- 1 -> 2 */
        brightness?: number;
        compression?: 'fast' | 'none'
        contrast?: number;
        dpr?: number;
        fit?: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad';
        format?: 'auto';
        gamma?: number;
        /** When cropping with fit: "cover" and fit: "crop", this parameter defines the side or point that should not be cropped. ("left", "right", "top", "bottom") or coordinates specified on a scale from 0.0 (top or left) to 1.0 (bottom or right): 0.5x0.5 is the center, 0.5x0.33 is a point in the top third of the image. */
        gravity?: | 'auto' | 'top' | 'right' | 'bottom' | 'left' | [number, number];
        height?: number;
        width?: number;
        metadata?: 'none' | 'copyright' | 'keep';
        onerror?: 'redirect';
        /** 1 <-> 100 */
        quality?: number;
        rotate?: '90' | '180' | '270';
        /** 0 (no sharpening, default) and 10 (maximum). 1 is a recommended value for downscaled images. */
        sharpen?: number;
        /** top;right;bottom;left -> 20;30;20;0 */
        trim?: | string;

        // watermark?: {
        //     url: string;
        //     opacity?: number;
        //     width?: number;
        //     height?: number;
        //     fit?: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad';
        //     gravity?: 'center' | 'top' | 'right' | 'bottom' | 'left' | 'auto';
        // };
    }

    interface Props extends AirImgVariant {
        debug?: boolean;
        preload?: boolean;
        src: string,
        defaultVariant: Array<AirImgVariant>,
        smVariant?: Array<AirImgVariant>,
        mdVariant?: Array<AirImgVariant>,
        lgVariant?: Array<AirImgVariant>,
        xlVariant?: Array<AirImgVariant>,
        xxlVariant?: Array<AirImgVariant>,
        smBkp?: string,
        mdBkp?: string,
        lgBkp?: string,
        xlBkp?: string,
        xxlBkp?: string
    }
</script>

<style></style>
